// Small Tea farmer Application Submission
const teaFarmerApplication = '/btb/'
export const teaFarmerApplicationListApi = teaFarmerApplication + 'small-tea-farmer-application/list'
export const teaFarmerApplicationDetailsApi = teaFarmerApplication + 'small-tea-farmer-application/details'
export const teaFarmerApplicationGenInfoStoreApi = teaFarmerApplication + 'small-tea-farmer-application/store'
export const teaFarmerApplicationOwnerInfoStoreApi = teaFarmerApplication + 'small-tea-farmer-application/owner-info-store'
export const smallTeaFarmerViewPdf = teaFarmerApplication + 'small-tea-farmer-application/view-pdf'

// Tree Disposal Application Submission
export const treeDisposalAppListApi = '/btb/tree-disposal-application/list'
export const treeDisposalAppDetailsApi = '/btb/tree-disposal-application/details'
export const treeDisposalAppStoreApi = '/btb/tree-disposal-application/store'
export const treeDisposalAppCuttingPurposeStoreApi = '/btb/tree-disposal-application/cutting-purpose'
export const treeDisposalAppCuttingDevelopmentStoreApi = '/btb/tree-disposal-application/cutting-development-planning'
export const gardenSectionDetailsApi = '/btb/tree-disposal-application/garden-section-details'
export const treeDisposalPreviousPermissionDetailsApi = '/btb/tree-disposal-application/previous-permission-history'
export const gardenInfoDetails = '/btb/garden-info-details'
const masterTree = '/configuration/tree-entry/'
export const customTreeEntryApi = masterTree + 'store'
export const getTeaGardenNameList = '/btb/get-tea-garden-name-list'
export const TreeDisposalViewPdf = '/btb/tree-disposal-application/view-pdf'
export const AuthUserInfo = '/user-management/user/get-user-details/'

const teaGardenPanel = 'tea-garden-panel/'
// Small Tea Garden Application
const gardenInfo = 'tea-garden-application/garden-info/'
export const gardenInfoListApi = teaGardenPanel + gardenInfo + 'list'
export const gardenInfoShowApi = teaGardenPanel + gardenInfo + 'show'
export const gardenInfoStoreApi = teaGardenPanel + gardenInfo + 'store'
export const gardenInfoUpdateApi = teaGardenPanel + gardenInfo + 'update'

const proprietorIdentity = 'tea-garden-application/proprietor-identity/'
export const proprietorIdentityStoreApi = teaGardenPanel + proprietorIdentity + 'store'
export const proprietorIdentityUpdateApi = teaGardenPanel + proprietorIdentity + 'update'

const landOwnershipInfo = 'tea-garden-application/land-ownership-info/'
export const landOwnershipInfoStoreApi = teaGardenPanel + landOwnershipInfo + 'store'
export const landOwnershipInfoUpdateApi = teaGardenPanel + landOwnershipInfo + 'update'

const otherInfo = 'tea-garden-application/other-info/'
export const otherInfoStoreApi = teaGardenPanel + otherInfo + 'store'
export const otherInfoUpdateApi = teaGardenPanel + otherInfo + 'update'

const witnessInfo = 'tea-garden-application/witness-info/'
export const witnessInfoStoreApi = teaGardenPanel + witnessInfo + 'store'
export const witnessInfoUpdateApi = teaGardenPanel + witnessInfo + 'update'

// Tea factory Application Submission
const factoryInfo = 'tea-factory-application/'
export const factoryInfoListApi = teaGardenPanel + factoryInfo + 'list'
export const factoryInfoDetailsApi = teaGardenPanel + factoryInfo + 'details'
export const factoryInfoApplicantInfoStoreApi = teaGardenPanel + factoryInfo + 'store'
export const factoryInfoStoreApi = teaGardenPanel + factoryInfo + 'factory-info-store'
export const factoryInfoMachineriesInfoStoreApi = teaGardenPanel + factoryInfo + 'machineries-info-store'
export const factoryInfoAttachmentInfoStoreApi = teaGardenPanel + factoryInfo + 'attachment-info-store'
export const TeaFactoryViewPdf = '/tea-garden-panel/tea-factory-application/view-pdf'

export const applicationPaymentOffline = '/btb/store-offline-payment'
export const applicationPaymentOnline = '/btb/store-online-payment'
export const applicationPaymentDetails = '/btb/get-btb-parent-service'
// payment redirect route
export const paymentSuccessBtb = '/btb/success'
export const PaymentCancelBtb = '/btb/cancel'
export const PaymentDeclineBtb = '/btb/decline'
// btb fertilizer circular route
const circular = '/btb/circular/'
export const circularList = circular + 'list'
export const circularStore = circular + 'store'
export const circularUpdate = circular + 'update'
export const circularToggle = circular + 'toggle-status'
export const circularShow = circular + 'show'

// Fertilizer Request Process routes
const fertlizerReqProcesBaseUrl = '/btb/fertilizer-request-process/'
export const fertilizerIndentCirList = fertlizerReqProcesBaseUrl + 'circular-app-list'
export const fertilizerIndentSubList = fertlizerReqProcesBaseUrl + 'list'
export const fertilizerIndentAssign = fertlizerReqProcesBaseUrl + 'assign'
export const fertilizerIndentAssignMultiple = fertlizerReqProcesBaseUrl + 'assign-multiple'
export const fertilizerIndentCirReceivedList = fertlizerReqProcesBaseUrl + 'circular-received-list'
export const fertilizerIndentReceivedList = fertlizerReqProcesBaseUrl + 'received-list'
export const fertilizerIndentForward = fertlizerReqProcesBaseUrl + 'forward'
export const fertilizerIndentForwardMultiple = fertlizerReqProcesBaseUrl + 'forward-multiple'
export const fertilizerIndentCirForwardedList = fertlizerReqProcesBaseUrl + 'circular-forwarded-list'
export const fertilizerIndentForwardList = fertlizerReqProcesBaseUrl + 'forwarded-list'
export const fertilizerIndentVerify = fertlizerReqProcesBaseUrl + 'verify'
export const fertilizerIndentCirVerifiedList = fertlizerReqProcesBaseUrl + 'circular-verified-list'
export const fertilizerIndentVerifiedList = fertlizerReqProcesBaseUrl + 'verified-list'
export const fertilizerIndentApprove = fertlizerReqProcesBaseUrl + 'approve'
export const fertilizerIndentCirApprovedList = fertlizerReqProcesBaseUrl + 'circular-approved-list'
export const fertilizerIndentApprovedList = fertlizerReqProcesBaseUrl + 'approved-list'
export const fertilizerIndentAllocate = fertlizerReqProcesBaseUrl + 'allocate'
export const fertilizerIndentCirAllocatedList = fertlizerReqProcesBaseUrl + 'circular-allocated-list'
export const fertilizerIndentAllocatedList = fertlizerReqProcesBaseUrl + 'allocated-list'
export const fertilizerIndentPriceEntry = fertlizerReqProcesBaseUrl + 'price-entry'
export const fertilizerIndentCirFinalAllocatedList = fertlizerReqProcesBaseUrl + 'circular-final-allocated-list'
export const fertilizerIndentFinalAllocatedList = fertlizerReqProcesBaseUrl + 'final-allocated-list'
export const fertilizerIndentCirNomineeApproveList = fertlizerReqProcesBaseUrl + 'circular-nominee-approve-list'
export const fertilizerIndentNomineeApproveList = fertlizerReqProcesBaseUrl + 'nominee-approve-list'
export const fertilizerIndentNomineeApprove = fertlizerReqProcesBaseUrl + 'nominee-approve'
// fertilizer request report
export const fertilizerRequestReportList = fertlizerReqProcesBaseUrl + 'fertilizer-request-report'
// annual Production Target route
const annualProductionTarget = '/btb/annual-production-target/'
export const annualProductionTargetList = annualProductionTarget + 'list'
export const annualProductionTargetStore = annualProductionTarget + 'store'
export const annualProductionTargetUpdate = annualProductionTarget + 'edit'
export const annualProductionTargetToggle = annualProductionTarget + 'toggle-status'
export const annualProductionTargetShow = annualProductionTarget + 'show'
// country wise export route
const countryWiseExport = '/btb/country-wise-export/'
export const countryWiseExportList = countryWiseExport + 'list'
export const countryWiseExportStore = countryWiseExport + 'store'
export const countryWiseExportUpdate = countryWiseExport + 'edit'
export const countryWiseExportToggle = countryWiseExport + 'toggle-status'
export const countryWiseExportShow = countryWiseExport + 'show'
// btb country wise import route
const countryWiseImportBaseUrl = '/btb/country-wise-import/'
export const countryWiseImportList = countryWiseImportBaseUrl + 'list'
export const countryWiseImportStore = countryWiseImportBaseUrl + 'store'
export const countryWiseImportUpdate = countryWiseImportBaseUrl + 'edit'
export const countryWiseImportToggle = countryWiseImportBaseUrl + 'toggle-status'
export const countryWiseImportShow = countryWiseImportBaseUrl + 'show'

// btb country wise import route
const auctionSaleBaseUrl = '/btb/auction-sale/'
export const auctionSaleList = auctionSaleBaseUrl + 'list'
export const auctionSaleStore = auctionSaleBaseUrl + 'store'
export const auctionSaleUpdate = auctionSaleBaseUrl + 'edit'
export const auctionSaleToggle = auctionSaleBaseUrl + 'toggle-status'
export const auctionSaleShow = auctionSaleBaseUrl + 'show'

// return management
const returnGarden = '/btb/return-management/'
// return - one apis
export const returnOneListApi = returnGarden + 'return-one/list'
export const returnOneDetailsApi = returnGarden + 'return-one/details'
export const returnOneDetailsPdf = returnGarden + 'return-one/details-pdf'
export const returnOneTabOneStoreApi = returnGarden + 'return-one/store-tab-one'
export const returnOneTabTwoStoreApi = returnGarden + 'return-one/store-tab-two'
export const returnOneTabThreeStoreApi = returnGarden + 'return-one/store-tab-three'
export const returnOneTabFourStoreApi = returnGarden + 'return-one/store-tab-four'
export const returnOneTabLastStoreApi = returnGarden + 'return-one/store-tab-last'
// btb return - three apis
export const returnThreeListApi = returnGarden + 'return-three/list'
export const returnThreeStoreApi = returnGarden + 'return-three/store'
export const returnThreeUpdateApi = returnGarden + 'return-three/update'
export const returnThreeShowApi = returnGarden + 'return-three/show'
export const preYearData = returnGarden + 'return-three/preYearData'

// btb country wise import route
const bulletinUrl = '/btb/bulletin/'
export const annualProductionApi = bulletinUrl + 'annual-production'
export const monthWiseProductionApi = bulletinUrl + 'month-wise-production'
export const exportInternalApi = bulletinUrl + 'export-internal-account'
export const auctionSaleApi = bulletinUrl + 'auction-sale-report'
export const countryWiseExportReportApi = bulletinUrl + 'country-wise-export-report'
export const countryWiseImportReportApi = bulletinUrl + 'country-wise-import-report'
export const exporterExportReportApi = bulletinUrl + 'exporter-export-report'
export const comparisonExporter = bulletinUrl + 'comparison-exporter-export-report'
export const exGardenSaleReportApi = bulletinUrl + 'ex-garden-sell-report'
export const annualExportReportApi = bulletinUrl + 'annual-export-report'
// return two report routes
const returnTwoReport = '/btb/return-two-report/'
export const netTeaProductionApi = returnTwoReport + 'net-tea-production'

export const teaTypeNetProductionReportApi = returnTwoReport + 'tea-type-wise-production'
// license report
export const areaWiseLicenseReportApi = '/btb/report/area-wise-license-report'
export const financialSummaryReportApi = '/btb/report/financial-summary-license-report'

// return one summery report
export const returnOneSummeryReportApi = '/btb/return-management/return-one-report/summery'
// return one details report
export const returnOneDetailReportApi = '/btb/return-management/return-one-detail-report/detail'
// return two summery report
export const returnTwoSummeryReportApi = '/btb/return-management/return-two-report/summery'
// return three summery report
export const returnThreeSummeryReportApi = '/btb/return-management/return-three-report/summery'
// return three details report
export const returnThreeDetailReportApi = '/btb/return-management/return-three-detail-report/detail'
// ex-garden advance sess amount get api
export const exGardenAdvSessAmountApi = '/btb/ex-garden-app/previous-adv-sess-amount'

// national tea award api
const nationalWardApi = '/btb/national-tea-award/'

export const awardCategoryListApi = nationalWardApi + 'category/list'
export const awardCategoryToggleApi = nationalWardApi + 'category/toggle-status'
export const awardCategoryStoreApi = nationalWardApi + 'category/store'
export const awardCategoryUpdateApi = nationalWardApi + 'category/update'

export const awardCircularList = nationalWardApi + 'circular/list'
export const awardCircularToggleApi = nationalWardApi + 'circular/toggle-status'
export const awardCircularStoreApi = nationalWardApi + 'circular/store'
export const awardCircularUpdateApi = nationalWardApi + 'circular/update'
export const awardCircularShowApi = nationalWardApi + 'circular/show'
export const btbTeaAwardApplicationListApi = nationalWardApi + 'application/list'
export const btbTeaAwardApplicationApprovedListApi = nationalWardApi + 'application/approved-list'
export const btbTeaAwardApplicationReceivedApi = nationalWardApi + 'application/received-multiple'
export const btbTeaAwardApplicationMarkAssignApi = nationalWardApi + 'application/mark-assign-approve'
export const editApplication = '/portal/btb/application/update'
